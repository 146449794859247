<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_extras">Extras</router-link></li>
              <li>Extra hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_extras" class="button button-grey button-small">Alle Extras ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <form @submit="create_vehicle_extra" class="form" method="post">

              <div class="form-wrap">
                <label for="name">Name</label>
                <input v-model="vehicle_extra.name" class="form-input" type="text" name="name" id="name" placeholder="Name">
              </div>

              <div class="form-wrap">
                <label for="description">Beschreibung</label>
                <input v-model="vehicle_extra.description" class="form-input" type="text" name="description" id="description" placeholder="Beschreibung">
              </div>

              <div class="form-wrap">
                <label for="price">Preis</label>
                <input v-model="vehicle_extra.price" class="form-input" type="text" name="price" id="price" placeholder="Preis">
              </div>

              <div class="form-wrap">
                <input type="checkbox" id="checkbox" v-model="vehicle_extra.enabled">
                <label for="checkbox">Aktiviert?</label>
              </div>

              <div class="form-wrap select-wrap">
                <label for="street_number">Kategorie</label>
                <select v-model="vehicle_extra.category" class="select-wrap">
                  <option value="insurance">Versicherung</option>
                  <option value="kilometer">Kilometer</option>
                  <option value="extra">Extra</option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="price_type">Preis Typ</label>
                <select v-model="vehicle_extra.price_type" class="select-wrap">
                  <option value="once">Einmalig</option>
                  <option value="per_day">Pro Tag</option>
                </select>
              </div>

              <p>
                <input type="submit" value="Extra erstellen ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_vehicle_extras',
  components: {
    AdminNav
  },
  data () {
    return {
      vehicle_extra: {}
    }
  },
  methods: {
    create_vehicle_extra: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_extras', this.vehicle_extra, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
        this.$router.push('/admin/vehicle_extras');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    }
  },
  mounted () {}
}
</script>

<style lang="scss">

</style>
